import * as React from 'react'
import { Component } from 'react';
import { Link, RouteComponentProps } from "react-router-dom";
import { Form, Row, Col, Button, Input } from "antd-min";
import { SchoolPathConfig as PathConfig } from "../../../config/pathconfig";
import { lazyInject, TYPES } from '../../../util/index';
import { withRouter, GLForm, FormHelper, GLFormComponentProps, GLUtil, FormItemsLayout, GLGlobal, StateType, connect } from "gl-commonui";
import { FormattedMessage } from "react-intl";
import { validate } from '../../../states/school//acceptinvitation';
import { InvitationType } from '../../../util';
import { SchoolLocale } from '../../../locales/localeid';

var Recaptcha = require('react-recaptcha');
const { renderFormItem } = FormHelper;
type ValidateStatus = { hasFeedback?: boolean, status?: "success" | "warning" | "error" | "validating" }

export interface ValidateCodeProps extends GLFormComponentProps {
    isSignin?: boolean
    valid?: boolean
    validate: (param, callback) => void
}
interface ValidateCodeStates {
    isValid?: ValidateStatus
    isClicked?: boolean
}

const mapStateToProps = (state: StateType) => {
    return {
        isSignin: state.oidc.loginInfo && state.oidc.loginInfo.loggedin,
    }
}

@withRouter
@GLForm.create()
@connect(({ acceptinvitation: { valid }, oidc: { loginInfo } }: StateType) => ({
    valid,
    isSignin: loginInfo && loginInfo.loggedin
}), {
        validate,
    })
export class ValidateCodePage extends Component<ValidateCodeProps & RouteComponentProps<any>, ValidateCodeStates> {
    siteKey = "6LfmDlYnAAAAAJTgGfjkvnHEjs1o2ml33_VPGVt8"
    status = {
        hasFeedback: true,
        status: 'validating'
    } as ValidateStatus
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        const { setFieldsValue } = this.props.form;
        nextProps.location !== this.props.location && setFieldsValue({ invitationCode:  GLUtil.queryParse().code });
    }

    componentDidMount() {
        this.setState({ isClicked: false });
    }

    validateCode(rule, value, callback) {
        const params = {
            invitationType: [InvitationType.AccountManager, InvitationType.SchoolAdmin, InvitationType.CampusAdmin,
                             InvitationType.ClassTeacher, InvitationType.SubTeacher, InvitationType.SchoolTeacher, InvitationType.ContentAdmin],
            code: value.trim()
        }

        this.props.validate(params, callback);
    }

    callback() {
        console.log("done");
    }

    recaptchaCallback(flag) {
        this.setState({ isClicked: flag });
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const code = values.invitationCode.trim();
                this.props.history.push({ pathname: PathConfig.Inviting, search: GLUtil.queryStringify({ code: code }) });
            }
        });
    }
    render() {
        const code = GLUtil.queryParse().code;
        const { getFieldDecorator } = this.props.form;
        let isShow = this.props.isSignin ? "hide" : "show";
        let submitstyle = !this.props.isSignin && !this.state.isClicked ? "isforbid" : "";
        this.status.status  = this.props.valid ? 'success' : 'error';
        return <Row>
            <Row className='invitationsubject'>
                <FormattedMessage id={SchoolLocale.InvitationSubject} ></FormattedMessage>
            </Row>
            <Row className='invitationtitle'>
                <FormattedMessage id={SchoolLocale.InvitationCodeTitle} ></FormattedMessage>
            </Row>
            <GLForm className='acceptinvitation' form={this.props.form}   onSubmit={this.handleSubmit.bind(this)}>
                <FormItemsLayout style={{width:'304px', marginTop:"20px"}}>
                    <Form.Item
                        label={<FormattedMessage id={ SchoolLocale.InvitationValidCodes } />}
                        required={true}
                        // {...this.status}
                    >
                        {getFieldDecorator('invitationCode', {
                            initialValue: code,
                            validateFirst: true,
                            validateTrigger: [],
                            rules: [{
                                required: true,
                                whitespace: true,
                                message: GLGlobal.intl.formatMessage({ id: SchoolLocale.ValidMsg })
                            }, {
                                validator: this.validateCode.bind(this)
                            }],
                        })(
                            <Input placeholder="" style={{ marginRight: 8 }} />
                            )}
                    </Form.Item>
                    <Form.Item className={isShow} style={{ marginBottom: "24px" }}>
                        {getFieldDecorator('g-recaptcha')(
                            <Recaptcha sitekey={this.siteKey}
                                render="explicit"
                                expiredCallback={() => this.recaptchaCallback(false)}
                                onloadCallback={() => this.callback()}
                                verifyCallback={() => this.recaptchaCallback(true)}/>
                        )}
                    </Form.Item>
                    <Form.Item className='tableAction'>
                        <Button className={submitstyle} type="primary" htmlType="submit">
                            {GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationNext })}
                        </Button>
                    </Form.Item>
                </FormItemsLayout>
            </GLForm>
        </Row>;
    }
}
